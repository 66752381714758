<template>
<div>
  <div class="d-flex">
    <img class="banner-image" src="/img/home-bg.jpg" alt="banner">
    <div class="banner-content">
      <div class="banner-title">
        <span class="ua-letter-1">U</span>
        <span class="ua-letter-2">A</span><br>
        World<br>
        of<br>
        Warcraft
      </div>
      <div class="banner-text">
        «Один в полі не воїн» &copy; Якийсь Дід.
        Відкрий світ українського World of Warcraft, приєднуйся до Кнайпи Вар'ятів.
      </div>
      <div class="banner-action">
        <a href="javascript:;" @click="readMoreClick">Детальніше</a>
      </div>
    </div>
  </div>
  <div class="app-container py-sm-10 py-md-16" ref="more-content">
    <div class="app-reading-column">
      <div class="main-column">
        <div :inner-html.prop="appPosts.find(p => p.slug == 'home').content | htmlize"></div>
        <h2 class="mt-12">Наші пригоди</h2>
        <p>
          <v-img class="player app-yt-player-wrap" aspect-ratio="1.778">
            <youtube class="yt-player" video-id="VHuH7pVVrKs" />
          </v-img>
        </p>
        <p>
          <v-img class="player app-yt-player-wrap" aspect-ratio="1.778">
            <youtube class="yt-player" video-id="TGJa2qbpPNU" />
          </v-img>
        </p>
        <p>
          <v-img class="player app-yt-player-wrap" aspect-ratio="1.778">
            <youtube class="yt-player" video-id="rUeScscF4qs" />
          </v-img>
        </p>
        <p>
          <v-img class="player app-yt-player-wrap" aspect-ratio="1.778">
            <youtube class="yt-player" video-id="3fWF7Yt4kqw" />
          </v-img>
        </p>
        <p>В розділі <router-link class="link" to="/videos">Відео</router-link> є більше.</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  created: function () {
    this.setPageMetadata()
  },
  methods: {
    readMoreClick: function () {
      const navbar = window.document.querySelector('.app-navbar')
      const target = this.$refs['more-content']
      const top = target.getBoundingClientRect().top
      const extra = navbar.getBoundingClientRect().height
      const newY = window.scrollY + top - extra
      this.scroll(newY, 400)
      this.$gtag.event('home_read_more_click')
    },
    scroll: function (newScrollY, duration) {
      const oldScrollY = window.scrollY
      const diff = newScrollY - oldScrollY
      if (Math.abs(diff) > 0) {
        let start = false
        window.requestAnimationFrame(function step (now) {
          if (!start) {
            start = now
          }

          const elapsed = now - start
          const percent = Math.min(elapsed / duration, 1)
          window.scrollTo(0, oldScrollY + diff * percent)

          if (elapsed < duration) {
            window.requestAnimationFrame(step)
          }
        })
      }
    }
  }
}

</script>

<style scoped>

.banner-image {
  width: 100vw;
  height: calc(100vh - var(--navbar-min-height));
  object-fit: cover;
}

.banner-content {
  position: absolute;
  left: 12vw;
  right: 12vw;
  height: calc(100vh - var(--navbar-min-height));
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}

.banner-title {
  padding-top: .5em;
  font: var(--font-text-bold-maximum);
  font-size: 100px;
  line-height: .95;
  text-transform: uppercase;
}

.ua-letter-1 {
  color: deepskyblue;
}

.ua-letter-2 {
  color: yellow;
}

.banner-text {
  padding-top: 1em;
  width: 33%;
  font: var(--font-text-light);
  font-size: 26px;
  line-height: 1.6;
}

.banner-action {
  padding: 3em 0;
  font: var(--font-text);
  font-size: 16px;
}

.banner-action a {
  display: inline-block;
  padding: 1.6em 2em;
  color: #fff;
  text-decoration: none;
  background-color: #3e76c9;
  border-radius: 8px;
}

@media (max-width: 1399px) {
  .banner-title {
    font-size: 68px;
  }

  .banner-text {
    width: 60%;
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .banner-title {
    font-size: 56px;
  }

  .banner-text {
    font-size: 20px;
  }
}

@media (max-width: 599px) {
  .banner-content {
    left: 16px;
    right: 16px;
  }

  .banner-title {
    font-size: 40px;
  }

  .banner-text {
    width: 80%;
    font-size: 20px;
  }
}

@media (max-height: 600px) {
  .banner-image {
    height: 600px;
  }

  .banner-content {
    height: 600px;
    justify-content: flex-start;
  }

  .banner-title {
    font-size: 56px;
  }

  .banner-text {
    width: 60%;
    font-size: 20px;
  }
}

</style>
